import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { Modal, Flex, Button, List, Checkbox } from "antd-mobile";
import "./index.scss";
import healthTestApi from "api/healthTest";
import { NoData, EnterLoading } from "../../basic";
import { timestamp2time } from "utils/util";
const COMPONENT_ID = "ChooseTest";

interface ChooseTestOperation {
  defaultValue?: any[];
  count?: number; // 可选择最大数量
  onComplete(value: any[]): void; // 完成回调
}
interface ChooseTestProps extends React.FC<{ operation: ChooseTestOperation }> {
  create(operation: ChooseTestOperation): void;
  destory(): void;
}
/**
 * ### 选择自测
 */
const ChooseTest: ChooseTestProps = (props) => {
  const { operation } = props;
  const { onComplete, count = 3, defaultValue } = operation;
  const [show, setShow] = useState(true);
  const [error, setError] = useState("");
  const [enterLoading, setEnterLoading] = useState(false);
  const [selected, setSelected] = useState(() => {
    return defaultValue || [];
  });

  const [testList, setTestList] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNum, setPageNum] = useState(1);

  const initChooseTest = useCallback(() => {
    if(enterLoading || !hasMore)return;
    setEnterLoading(true);
    const pms = healthTestApi.getUserTestRecords({
      page: pageNum,
      limit: 10
    });
    pms.then((result) => {
      if(result.current_page === 1){
        setTestList(result.data);
      }else{
        setTestList([...testList, ...result.data]);
      }

      let more = result.current_page < result.last_page
      if(more){
        setHasMore(more);
        setPageNum(result.current_page + 1)
      }
    });
    pms.catch((error) => {
      setError(error);
    });
    pms.finally(() => {
      setEnterLoading(false);
    });
  }, [enterLoading, hasMore, pageNum, testList]);
  useEffect(() => {
    initChooseTest();
  }, [initChooseTest]);

  const destroy = () => {
    setTimeout(() => {
      const div = document.getElementById(COMPONENT_ID);
      if (div) {
        ReactDOM.unmountComponentAtNode(div);
        document.body.removeChild(div);
      }
    }, 300);
  };
  const handleCancle = () => {
    setShow(false);
    destroy();
  };
  const handleComplete = useCallback(() => {
    setShow(false);
    onComplete && onComplete(selected);
    destroy();
  }, [selected, onComplete]);

  const handleSelected = useCallback(
    (item: any) => {
      const index = selected.findIndex((i) => i.result_id === item.result_id);
      if (index >= 0) {
        selected.splice(index, 1);
      } else {
        selected.push(item);
      }
      setSelected([...selected]);
    },
    [selected]
  );

  if (error) {
    return <NoData title={error} />;
  } else {
    return (
      <Modal
        popup
        visible={show}
        onClose={() => setShow(false)}
        animationType="slide-up"
      >
        <div className={`GlobalComponent-${COMPONENT_ID}`}>
          {enterLoading ? (
            <EnterLoading />
          ) : (
            <>
              <div className="Header">选择自测</div>
              <List className="Custom-List">
                {testList && testList.length > 0 ? (
                  testList.map((row: any, index: number) => {
                    const checked =
                      selected.findIndex(
                        (item) => item.result_id === row.result_id
                      ) >= 0;
                    return (
                      <Checkbox.CheckboxItem
                        key={index}
                        checked={checked}
                        className="Consult-Item"
                        disabled={!checked && selected.length >= count}
                        onChange={() => handleSelected(row)}
                      >
                        <Flex direction="column" align="start">
                          <div className="Title">{row.paper_name}</div>
                          <div className="SubTitle">
                            测试时间：{timestamp2time(row.createtime)}
                          </div>
                          <div className="SubTitle">
                            综合评分：{row.paper_score}
                          </div>
                          <div
                            className="SubTitle"
                            dangerouslySetInnerHTML={{
                              __html: row.paper_result,
                            }}
                          ></div>
                        </Flex>
                      </Checkbox.CheckboxItem>
                    );
                  })
                ) : (
                  <NoData />
                )}
              </List>
            </>
          )}
          <Flex align="end" justify="end" className="Actions">
            <Flex.Item>
              <Button
                type="ghost"
                style={{ background: "#fff" }}
                onClick={() => handleCancle()}
              >
                取消
              </Button>
            </Flex.Item>
            <Flex.Item style={{ margin: "0" }}>
              <Button type="primary" onClick={() => handleComplete()}>
                完成({`${selected.length}/${count}`})
              </Button>
            </Flex.Item>
          </Flex>
        </div>
      </Modal>
    );
  }
};

ChooseTest.create = (operation: ChooseTestOperation) => {
  let div = document.createElement("div");
  div.id = COMPONENT_ID;
  document.body.appendChild(div);
  let instance = ReactDOM.render(
    React.createElement(ChooseTest, { operation }),
    div
  );
  return {
    instance,
    destroy() {
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
    },
  };
};

ChooseTest.destory = () => {
  const div = document.getElementById(COMPONENT_ID);
  if (div) {
    ReactDOM.unmountComponentAtNode(div);
    document.body.removeChild(div);
  }
};

export default ChooseTest;
